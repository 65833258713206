.App {
  width: 100%;
}

.App1 {
  font-family: sans-serif;
}

.Topbar {
  background-color: #4dcba2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.Header {
  font-family: sans-serif;
  text-align: center;
  font-size: 24pt;
}

.flex-container0 {
  display: flex;
  flex-direction: column;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  border-top-style: solid;
  border-top-color: black;
}

.flex-container2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Lp1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #4ecca3;
  padding: 20px;
  border-radius: 5px;
}

.topbanner {
  height: 200px;
  background-image: url("./lp_bg.png");
  background-size: auto 180px;
  background-repeat: no-repeat;
  background-position: center top;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 60px;
  padding-right: 60px;
}

.container {
  text-align: center;
}

.schoolinfo {
  text-align: left;
  font: normal normal medium 20px/24px Roboto;
  letter-spacing: 0px;
  color: #919191;
  opacity: 1;
}

.schoolinfo1 {
  width: 400px;
}

.schoolinfo2 {
  text-align: left;
  width: 400px;
  margin-right: 50px;
  margin-left: 50px;
}

.schoolinfo3 {
  display: grid;
  grid-template-columns: 1fr 2fr;

  text-align: left;
  width: 100%;
  margin-right: 50px;
}

.textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

.message {
  color: red;
}

.btn-krujob {
  background-color: white !important;
  color: #a2a3a2 !important;
}
.btn-krujob:focus {
  background-color: white;
  color: #a2a3a2;
}
.btn-krujob:hover {
  background-color: white;
  color: #a2a3a2;
}
.btn-krujob:active {
  background-color: white;
  color: #a2a3a2;
}

.btn-krujobgreen {
  background-color: #4ecca3 !important;
  color: white !important;
}
.btn-krujobgreen:focus {
  background-color: #4ecca3;
  color: white;
}
.btn-krujobgreen:hover {
  background-color: #4ecca3;
  color: white;
}
.btn-krujobgreen:active {
  background-color: #4ecca3;
  color: white;
}

.fullwidth {
  width: 100%;
}

.mediumwidth {
  width: 50%;
}

.iconmargin {
  margin-right: 10px;
  width: 15px;
}

.schooliconmargin {
  /* margin-right: 50px; */
  /* margin-left: 50px; */
  width: 200px;
}

.schooltitle {
  text-align: left;
  font: normal normal bold 20px/42px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.plan1 {
  flex-direction: column;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
}

.planhead1 {
  font-size: 15pt;
  text-align: left;
  padding: 5px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: orange;
}

.planhead2 {
  font-size: 15pt;
  text-align: left;
  padding: 5px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: #4ecca3;
}

.flex-container3 {
  background-color: orange;
  width: 100px;
  margin: 10px;
  text-align: left;
  line-height: 75px;
  font-size: 30px;
}

.flex-container6 {
  width: 100px;
  margin: 10px;
  text-align: left;
  line-height: 75px;
  font-size: 30px;
}

.planpointline {
  border-bottom-style: dotted;
  border-bottom-width: 2px;
  border-bottom-color: orange;
  padding-bottom: 15px;
  padding-left: 10px;
}

.planpoint {
  color: white;
  background-color: orange;
  padding: 10px;
  border-radius: 7px;
  margin-right: 10px;
}

.fontplan {
  color: red;
  font-weight: bold;
}

.planstrongpoint {
  color: orange;
  font-weight: bold;
}

.planstrongpointline {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: orange;
  padding-left: 10px;
}

.planprice {
  flex-direction: column;
  font-size: 20pt;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
}

.flex-container1 {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.flex-container4 {
  display: flex;
  justify-content: center;
}

.flex-container5 {
  align-self: flex-end;
}

.completepost1 {
  font-size: 15pt;
  display: flex;
  justify-content: center;
  padding: 30px;
  border-radius: 5px;
}

.completepost2 {
  font-size: 13pt;
  display: flex;
  justify-content: center;
  padding: 30px;
  border-radius: 5px;
}

.image-preview {
  width: 100px;
}
